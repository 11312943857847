<template>
  <div class="main-block">
    <div class="main-block-title">Create account</div>
    <div class="main-block-content">

      <form @submit.prevent="submit">
        <div class="grid">

          <form-group label="Email" :value="form.email" :errors="errors.email">
            <input data-test="email" v-model="form.email" @focusin="isValidation.email = true" @focusout="validate(['email'])" type="text" class="form-control" placeholder="username@gmail.com"/>
            <div v-if="errors.email && errors.email.includes('This email address has already been taken.')" class="send-code-message">
              <span v-if="sendLinkStatus === 'init'">If you want to continue registration please click <span class="as-link" data-test="send_continue_link" @click.prevent="sendContinueLink">here</span></span>
              <span class="success" v-else-if="sendLinkStatus === 'sent'">A link to continue registration was successfully sent to your email</span>
              <span class="error" v-else>Something was wrong. Please try again later.</span>
            </div>
            <send-code-button v-else type="email" :value="sendCodeForm" :disabled="hasError(errors.email) || isValidation.email"/>
          </form-group>

          <form-group label="Email Code" :value="form.email_code" :errors="errors.email_code">
            <input data-test="email_code" v-model="form.email_code" @change="validate(['email_code'])" type="text" class="form-control"/>
          </form-group>

          <form-group label="Phone" :value="form.phone" :errors="errors.phone">
            <input data-test="phone" v-model="form.phone" @focusout="validate(['phone'])" type="text" class="form-control" placeholder="+44 444444444"/>
          </form-group>

          <div class="hidden-mobile" />

          <form-group label="Password" autocomplete="new-password" :value="form.password" :errors="errors.password">
            <password-validation-helper :value="form.password" :show="showHelper">
              <input type="password"
                     data-test="password"
                     autocomplete
                     v-model="form.password"
                     @change="validate(['password'])"
                     class="form-control"
                     placeholder="*************"
                     @focusin="showHelper = true"
                     @focusout="showHelper = false"
              />
            </password-validation-helper>
            <div class="password-hint-block">Please use 8 to 30 characters with a mix of uppercase and lowercase letters, numbers & symbols</div>
          </form-group>

          <form-group label="Password confirmation" :value="form.password_confirm" :errors="errors.password_confirm">
            <input name="password_confirm" data-test="password_confirm" v-model="form.password_confirm" @input="validate(['password_confirm'])" type="password" class="form-control" placeholder="*************"/>
          </form-group>
        </div>

        <div class="form-buttons text-right">
          <button type="submit" class="btn-next" data-test="submit">
            <span>Save and proceed</span>
            <icon-next color="#19191C" size="15"/>
          </button>
        </div>
      </form>

    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex'

import FormGroup from "@/components/FormGroup";
import IconNext from '@/assets/icons/IconNext';
import SendCodeButton from "@/components/SendCodeButton";
import PasswordValidationHelper from "@/components/PasswordValidationHelper";

export default {
  name: 'CreateAccount',
  components: {
    PasswordValidationHelper,
    FormGroup,
    IconNext,
    SendCodeButton
  },
  props: {
    /**
     * Registration type
     * Legal or Individual
     */
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      form: {
        type: this.type,
        email: '',
        phone: '',
        email_code: '',
        password: '',
        password_confirm: ''
      },

      /**
       * Следим за тем, чтобы поля были
       * провалидированны успешно и только в этом
       * случае разрешаем отправлять коды
       */
      isValidation: {
        email: true,
        // phone: true
      },

      /**
       * Скрываем хелпер для поля ввода пароля
       */
      showHelper: false,

      errors: {},

      sendLinkStatus: 'init'
    }
  },
  created() {
    if (this.registrationHash) {
      this.$sdk.registration.get(this.registrationHash, 'user').then(response => {
        this.form.email = response.data.email
        this.form.phone = response.data.phone
      })
    }
  },
  computed: {
    ...mapState({
      registrationHash: state => state.registration.hash,
      registrationData: state => state.registration.data
    }),

    formData () {
      return {
        type: this.form.type,
        email: this.form.email,
        phone: this.form.phone,
        email_code: this.form.email_code,
        password: this.form.password,
        password_confirm: this.form.password_confirm,
        ref: this.refferal
      }
    },

    /**
     * Data for send code request
     * @returns {{email_code: string, phone, email}}
     */
    sendCodeForm () {
      return {
        phone: this.form.phone,
        email: this.form.email,
        email_code: this.form.email_code,
      }
    },

    /**
     * Detect agent id and add it to form data
     * @returns {string|undefined}
     */
    refferal () {
      if (
          this.$route
          && this.$route.query
          && Object.prototype.hasOwnProperty.call(this.$route.query, 'ref')
      ) {
        return this.$route.query.ref
      }

      return undefined
    }
  },
  methods: {
    submit () {
      this.$sdk.registration.start(this.type, this.formData, 0).then(response => {
        if (Array.isArray(response.data) && response.data.length === 0) {
          this.$store.dispatch('getRegistration', response.headers['x-id']).then(() => {
            this.$emit('next-step') // 6050de02cd01c
          })
        } else {
          this.errors = response.data
        }
      })
    },
    validate (attributes = []) {
      this.$sdk.registration.start(this.type, this.formData, 1).then(response => {
        for (const attr of attributes) {
          if (response.data[attr] !== undefined) {
            this.$set(this.errors, attr, response.data[attr])
          } else if (this.errors[attr] !== undefined) {
            this.$delete(this.errors, attr)
          }
        }
      }).finally(() => {
        let keys = Object.keys(this.isValidation)
        for (let key of keys) {
          if (attributes.includes(key)) {
            this.$set(this.isValidation, key, false)
          }
        }
      })
    },
    hasError (value) {
      if (Array.isArray(value) || (typeof value === 'string')) {
        return value.length > 0
      }

      return false
    },
    sendContinueLink () {
      this.$sdk.registration.resume(this.form.email, 0).then(() => {
        this.sendLinkStatus = 'sent'
      }).catch(() => {
        this.sendLinkStatus = 'error'
      }).finally(() => {
        setTimeout(() => {
          this.sendLinkStatus = 'init'
        }, 30000)
      })
    }
  }
}
</script>

<style lang="less">
.as-link {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    text-decoration: none;
  }
}
.password-hint-block {
  padding: 10px 10px 0 10px;
  font-size: 12px;
  line-height: 1.4em;
  color: #666;
  display: none;

  @media all and (max-width: 768px) {
    display: block;
  }
}

@media all and (max-width: 768px) {
  .hidden-mobile {
    display: none;
  }
}
</style>
