<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 14 11" :fill="color">
    <polygon :fill="color" transform="translate(-2 -4)" fill-rule="nonzero" points="7 14.42 2 9.42 3.41 8.01 7 11.59 14.59 4 16 5.42"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCheck',
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    size: {
      type: [String, Number],
      default: 18
    }
  }
}
</script>
