<template>
  <button class="send-code-wrp" type="button" :data-test="'send_code_' + type">
    <transition name="fade-ml" mode="out-in">
      <div class="send-code-message" v-show="status === -1">
        <span class="success">Confirmation code was sent.</span> You will be able to send the code again after {{ seconds }}
        seconds
      </div>
    </transition>

    <transition name="fade-ml" mode="out-in">
      <div class="send-code-message" v-show="status === -2">
        <span class="error">Confirmation service temporary unavailable</span> Please try again after {{ seconds }}
        seconds
      </div>
    </transition>

    <transition name="fade-ml" mode="out-in">
      <div class="send-code-message" v-show="status === -3">
        <span class="error">First you need to confirm your email</span> Please try again after {{ seconds }}
        seconds
      </div>
    </transition>

    <transition name="fade-ml" mode="out-in">
      <button type="button" v-show="status === 0" class="btn-send-code" @click.prevent="sendCode" :disabled="disabled || loading">
        <icon-mail color="#19191C" size="24"/>
        <span>Send code</span>
      </button>
    </transition>

    <transition name="fade-ml" mode="out-in">
      <button type="button" v-show="status === 1" class="btn-send-code" @click.prevent="resendCode" :disabled="disabled || loading">
        <icon-reload color="#19191C" size="24"/>
        <span>Resend code</span>
      </button>
    </transition>
  </button>
</template>

<script>
import IconReload from "../assets/icons/IconReload";
import IconMail from "../assets/icons/IconMail";

export default {
  name: 'SendCodeButton',
  components: {
    IconMail,
    IconReload
  },
  props: {
    /**
     * One of them: phone|email
     */
    type: {
      type: String,
      required: true,
      validator: function (value) {
        return ['phone', 'email'].indexOf(value) !== -1
      }
    },
    value: {
      type: Object,
      default: () => {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    timeout: {
      type: Number,
      default: 60
    }
  },
  data() {
    return {
      status: 0,
      seconds: 0,
      errors: {},

      loading: false
    }
  },
  methods: {
    sendCode() {
      this.loading = true

      this.$sdk.registration.sendCode(this.type, this.value).then(({ data }) => {
        this.status = Array.isArray(data) ? -1 : -3
      }).catch(() => {
        this.status = -2
      }).finally(() => {
        this.loading = false
        this.createTimeout(1)
      })
    },
    resendCode() {
      this.sendCode()
    },
    createTimeout (status) {
      this.seconds = this.timeout
      const interval = setInterval(() => {
        this.seconds--

        if (this.seconds === 1) {
          clearInterval(interval)
          this.status = status
        }
      }, 1000)
    },
  }
}
</script>

<style lang="less">
.send-code-wrp {
  position: relative;
  width: 100%;
  text-align: left;
  margin-top: 8px;
}
.send-code-message {
  font-size: 12px;
  line-height: 1.65em;
  color: #666;
  padding: 6px 10px 0 10px;
  border-radius: 3px;
  position: relative;
  display: block;
  width: 100%;
  background: #fff;



  span,
  p {
    font-size: 14px;
    font-weight: 500;
  }

  .error {
    color: #EA4C17;;
  }

  .success {
    color: #0da00d;
  }
}

.btn-send-code {
  padding-top: 3px;

  &:disabled {
    color: #999;

    svg path {
      fill: #999;
    }
  }

  * {
    vertical-align: middle;
  }

  span {
    margin-left: 5px;
  }
}
</style>
